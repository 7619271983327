import '../../App.css'
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { emptyUserInfo } from '../../redux/slice/userInfo/userInfoSlice';
import Appconfig from '../../config/config'

function Account() {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details

    const [activeCasino, setActiveCasino] = useState('popular')

    // const allCasino = [
    //     {
    //         'title': '20-20 TEENPATTI',
    //         'path': 'https://aura444.com/api/users/images/1%20Day%20Teen%20Patti-01%204.svg',
    //         'category': 'teen-patti'

    //     },
    //     {
    //         'title': 'LUCKY 7 - A',
    //         'path': '/other/LUCKY7-A.webp',
    //         'category': 'lucky-7'
    //     },
    //     {
    //         'title': '20-20 DRAGON TIGER',
    //         'path': '/other/DragonTiger.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': 'BACCARAT',
    //         'path': '/other/Baccarat-min.webp',
    //         'category': 'baccarat'

    //     },
    //     {
    //         'title': 'ANDAR BAHAR - A',
    //         'path': '/other/AndarBahar2-min-min.webp',
    //         'category': 'andar-bahar'

    //     },
    //     {
    //         'title': '32 CARDS - A',
    //         'path': '/other/32Cards .webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': '20-20 POKER -  A',
    //         'path': '/other/poker-min (1).webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': '1DAY TEEN PATTI',
    //         'path': '/other/I Day TeenPatti-min.webp',
    //         'category': 'teen-patti'
    //     },
    //     {
    //         'title': 'DRAGON TIGER',
    //         'path': '/other/Dragon-Tiger-min.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': 'FAST LUCKY - 7',
    //         'path': '/other/Fast lucky7-min.webp',
    //         'category': 'lucky-7'

    //     },
    //     {
    //         'title': '1 DAY DRAGON TIGER',
    //         'path': '/other/1 Day Dragon-Tiger-min.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': '29 CARD BACCARAT',
    //         'path': '/other/29 Baccarat-min.webp',
    //         'category': 'baccarat'

    //     },
    //     {
    //         'title': 'MUFLIS TEEN PATTI',
    //         'path': '/other/MUFLISTEENPATTI.webp',
    //         'category': 'teen-patti'
    //     },
    //     {
    //         'title': 'DTL - A',
    //         'path': '/other/DTL-01 3-min.webp',
    //         'category': 'dragon-tiger'

    //     },
    //     {
    //         'title': 'FAST DRAGON TIGER',
    //         'path': '/other/FAST DRAGON TIGER.webp',
    //         'category': 'dragon-tiger'
    //     },
    //     {
    //         'title': '3 CARD JUDGEMENT - A',
    //         'path': '/other/3 CARD JUDGEMENT - A.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'AMAR AKBAR ANTHONY',
    //         'path': '/other/AMAR AKBAR ANTHONY.webp',
    //         'category': 'bollywood'

    //     },
    //     {
    //         'title': 'DTL TEENPATTI',
    //         'path': '/other/DTL TEENPATTI.webp',
    //         'category': 'teen-patti'
    //     },
    //     {
    //         'title': 'INA MINA DIKA',
    //         'path': '/other/INA MINA DIKA.webp',
    //         'category': 'bollywood'

    //     },
    //     {
    //         'title': 'INSTANT WORLI',
    //         'path': '/other/INSTANT WORLI.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'BOLLYWOOD CASINO',
    //         'path': '/other/BOLLYWOOD CASINO.webp',
    //         'category': 'bollywood'

    //     },
    //     {
    //         'title': 'ANDAR BAHAR - C',
    //         'path': '/other/ANDAR BAHAR - C.webp',
    //         'category': 'andar-bahar'

    //     },
    //     {
    //         'title': 'EZUGI',
    //         'path': '/other/EZUGI.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'BETGAMES CASINO',
    //         'path': '/other/BETGAMES CASINO.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'TVBET',
    //         'path': '/other/TVBET.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'EVOLUTION',
    //         'path': '/other/EVOLUTION.webp',
    //         'category': 'poker'

    //     },
    //     {
    //         'title': 'LUCKY 7 - B',
    //         'path': '/other/LUCKY 7 - B.webp',
    //         'category': 'lucky-7'

    //     },
    //     {
    //         'title': '20-20 CARD RACE',
    //         'path': '/other/20-20 CARD RACE.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'BACCARAT - C',
    //         'path': '/other/BACCARAT - C.webp',
    //         'category': 'baccarat'

    //     },
    //     {
    //         'title': '1 CARD METER',
    //         'path': '/other/1 CARD METER.webp',
    //         'category': 'other'

    //     },
    //     {
    //         'title': 'TRIO',
    //         'path': '/other/TRIO.webp',
    //         'category': 'other'
    //     },

    // ]
    const allCasino = [
        {
            'title': 'POINT TEEN PATTI',
            'path': 'https://gamaexchange.com/api/users/images/Point Teen Patti-min.png',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['t20']
        },
        {
            'title': ' JOKER TEEN PATTI ',
            'path': 'https://gamaexchange.com/api/users/images/Joker Teen Patti-min.png',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['t20']
        },
        {
            'title': 'MUFLIS TEEN PATTI',
            'path': '/other/MUFLISTEENPATTI.webp',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['mulfisteenpatti']
        },
        {
            'title': '1DAY TEEN PATTI',
            'path': '/other/I Day TeenPatti-min.webp',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['1daytp']
        },
        {
            'title': 'DTL TEENPATTI',
            'path': '/other/DTL TEENPATTI.webp',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['dtlteenpatti']
        },
        {
            'title': '20-20 TEENPATTI',
            'path': 'https://aura444.com/api/users/images/1%20Day%20Teen%20Patti-01%204.svg',
            'category': 'teen-patti',
            'id': Appconfig.casino_type_id['t20']
        },
        {
            'title': '20-20 POKER - A',
            'path': '/other/poker-min (1).webp',
            'category': 'poker',
            'id': Appconfig.casino_type_id['poker20A']
        },
        {
            'title': '20-20 POKER - B',
            'path': 'https://gamaexchange.com/api/users/images/20-20 Poker-min.png',
            'category': 'poker',
            'id': Appconfig.casino_type_id['poker20A']
        },
        {
            'title': '29 CARD BACCARAT',
            'path': '/other/29 Baccarat-min.webp',
            'category': 'baccarat',
            'id': Appconfig.casino_type_id['baccaratcard29']
        },
        {
            'title': ' BACCARAT - A ',
            'path': 'https://gamaexchange.com/api/users/images/BACCARAT-2.png',
            'category': 'baccarat',
            'id': Appconfig.casino_type_id['baccarat-A']

        },
        {
            'title': 'BACCARAT',
            'path': '/other/Baccarat-min.webp',
            'category': 'baccarat',
            'id': Appconfig.casino_type_id['baccarat']
        },
        {
            'title': 'BACCARAT - C',
            'path': '/other/BACCARAT - C.webp',
            'category': 'baccarat',
            'url': 'BACCARAT-C',
            'id': Appconfig.casino_type_id['baccarat-C']
        },
        {
            'title': 'DRAGON TIGER',
            'path': '/other/Dragon-Tiger-min.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['dt']
        },
        {
            'title': '20-20 DRAGON TIGER',
            'path': '/other/DragonTiger.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['dt20']
        },
        {
            'title': 'FAST DRAGON TIGER',
            'path': '/other/FAST DRAGON TIGER.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['fdt']
        },
        {
            'title': '1 DAY DRAGON TIGER',
            'path': '/other/1 Day Dragon-Tiger-min.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['1daydt']
        },
        {
            'title': 'DTL - A',
            'path': '/other/DTL-01 3-min.webp',
            'category': 'dragon-tiger',
            'id': Appconfig.casino_type_id['1daydt']
        },
        {
            'title': '32 CARDS - B',
            'path': 'https://gamaexchange.com/api/users/images/32 cards-min-min.png',
            'category': 'other',
            'id': Appconfig.casino_type_id['32c']
        },
        {
            'title': '32 CARDS - A',
            'path': '/other/32Cards .webp',
            'category': 'other',
            'id': Appconfig.casino_type_id['32c']
        },

        {
            'title': 'ANDAR BAHAR - A',
            'path': '/other/AndarBahar2-min-min.webp',
            'category': 'andar-bahar',
            'id': Appconfig.casino_type_id['ab']
        },
        {
            'title': 'ANDAR BAHAR - C',
            'path': 'https://gamaexchange.com/api/users/images/AndarBahar3-min.png',
            'category': 'andar-bahar',
            'id': Appconfig.casino_type_id['ab']
        },
        {
            'title': 'LUCKY 7 - A',
            'path': '/other/LUCKY7-A.webp',
            'category': 'lucky-7',
            'id': Appconfig.casino_type_id['Lucky7A']
        },
        {
            'title': 'FAST LUCKY - 7',
            'path': '/other/Fast lucky7-min.webp',
            'category': 'lucky-7',
            'id': Appconfig.casino_type_id['fl7']
        },
        {
            'title': 'LUCKY 7 - B',
            'path': '/other/LUCKY 7 - B.webp',
            'category': 'lucky-7',
            'url': 'LUCKY-7-B',
            'id': Appconfig.casino_type_id['Lucky7B']

        },
        {
            'title': '3 CARD JUDGEMENT',
            'path': '/other/3 CARD JUDGEMENT - A.webp',
            'category': 'other',
            'id': Appconfig.casino_type_id['3cardJ']

        },
        {
            'title': 'Casino War',
            'path': '/other/CasinoWar-min.png',
            'category': 'other',
            'id': Appconfig.casino_type_id['casinowar']

        },
        {
            'title': 'INSTANT WORLI',
            'path': '/other/INSTANT WORLI.webp',
            'category': 'other',
            'id': Appconfig.casino_type_id['instantworli']
        },
        {
            'title': 'BOLLYWOOD CASINO',
            'path': '/other/BOLLYWOOD CASINO.webp',
            'category': 'bollywood',
        },
        {
            'title': 'INA MINA DIKA',
            'path': '/other/INA MINA DIKA.webp',
            'category': 'bollywood',
        },

        {
            'title': 'AMAR AKBAR',
            'path': '/other/AMAR AKBAR ANTHONY.webp',
            'category': 'bollywood',
        },
        {
            'title': 'LOTTERY',
            'path': '/other/Lottery 1-min.png',
            'category': 'other',
        },
        {
            'title': '20-20 CARD RACE',
            'path': '/other/20-20 CARD RACE.webp',
            'category': 'other',
            'url': '20-20-CARD-RACE'

        },
        {
            'title': '1 CARD METER',
            'path': '/other/1 CARD METER.webp',
            'category': 'other',
            'url': '1-CARD-METER'
        },
        {
            'title': '1 CARD 20-20',
            'path': '/other/1 Card-min.png',
            'category': 'other',
            // 'url': '1-CARD-METER'
        },
        {
            'title': 'TRIO',
            'path': '/other/TRIO.webp',
            'category': 'other',
            'url': 'TRIO'
        },
        {
            'title': 'KBC',
            'path': '/other/KBC-min.png',
            'category': 'other',
            'url': 'KBC'
        },
        {
            'title': 'CASINO METER',
            'path': '/other/CasinoMeter-min.png',
            'category': 'other',
            'url': 'CASINO-METER'
        },
        {
            'title': 'EVOLUTION',
            'path': '/other/EVOLUTION.webp',
            'category': 'poker',
            'url': 'EVOLUTION'
        },
        {
            'title': 'TVBET',
            'path': '/other/TVBET.webp',
            'category': 'poker',
        },
        {
            'title': 'BETGAMES CASINO',
            'path': '/other/BETGAMES CASINO.webp',
            'category': 'poker',
        },
        {
            'title': 'EZUGI',
            'path': '/other/EZUGI.webp',
            'category': 'poker',
        },
        {
            'title': 'VIMAAN',
            'path': '/other/',
            'category': 'other',
            'url': 'VIMAAN'
        },
        // {
        //     'title': 'EVOLUTION',
        //     'path': '/other/EVOLUTION.webp',
        //     'category': 'poker',
        //     'url': 'EVOLUTION'
        // },
        // {
        //     'title': 'TVBET',
        //     'path': '/other/TVBET.webp',
        //     'category': 'poker',
        // },
        // {
        //     'title': 'BETGAMES CASINO',
        //     'path': '/other/BETGAMES CASINO.webp',
        //     'category': 'poker',
        // },
        // {
        //     'title': 'EZUGI',
        //     'path': '/other/EZUGI.webp',
        //     'category': 'poker',
        // },
        // {
        //     'title': '20-20 DRAGON TIGER',
        //     'path': '/other/DragonTiger.webp',
        //     'category': 'dragon-tiger',
        //     'id': Appconfig.casino_type_id['dt20']
        // },
        // {
        //     'title': '1 DAY DRAGON TIGER',
        //     'path': '/other/1 Day Dragon-Tiger-min.webp',
        //     'category': 'dragon-tiger',
        //     'id': Appconfig.casino_type_id['1daydt']
        // },
        // {
        //     'title': 'FAST DRAGON TIGER',
        //     'path': '/other/FAST DRAGON TIGER.webp',
        //     'category': 'dragon-tiger',
        // },
        // {
        //     'title': 'INSTANT WORLI',
        //     'path': '/other/INSTANT WORLI.webp',
        //     'category': 'other',
        // },
        // {
        //     'title': 'BOLLYWOOD CASINO',
        //     'path': '/other/BOLLYWOOD CASINO.webp',
        //     'category': 'bollywood',
        // },
        // {
        //     'title': 'ANDAR BAHAR - C',
        //     'path': '/other/ANDAR BAHAR - C.webp',
        //     'category': 'andar-bahar',
        // },
        // {
        //     'title': '20-20 CARD RACE',
        //     'path': '/other/20-20 CARD RACE.webp',
        //     'category': 'other',
        //     'url': '20-20-CARD-RACE'

        // },
        // {
        //     'title': '1 CARD METER',
        //     'path': '/other/1 CARD METER.webp',
        //     'category': 'other',
        //     'url': '1-CARD-METER'
        // },
        // {
        //     'title': 'TRIO',
        //     'path': '/other/TRIO.webp',
        //     'category': 'other',
        //     'url': 'TRIO'
        // },

    ]
    const [casino, setCasino] = useState(allCasino)

    const logout = () => {
        localStorage.removeItem('userdata');
        localStorage.removeItem('login_token');
        dispatch(emptyUserInfo());

        navigate('/');
    }

    useEffect(() => {
        if (userInfo) {
            setCasino(activeCasino == 'popular' ? allCasino : allCasino.filter(e => e.category == activeCasino))
        }
        else {
            navigate('/login');
        }
    }, [activeCasino])

    return (
        <>
            {/* Desktop */}
            <div className='hidden lg:grid grid-cols-12 ' >
                <div className='col-span-12'>
                    <h3 className='!pl-[7.5px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] text-[1rem] leading-[2.2] text-center m-0 flex items-center flex-row justify-start font-medium'>
                        <svg className='w-[1rem] h-[1rem]' width="14" height="14" viewBox="0 0 14 14" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.96154 6.46154C9.96154 7.04727 9.78785 7.61986 9.46243 8.10688C9.13701 8.5939 8.67448 8.97349 8.13333 9.19764C7.59218 9.42179 6.99672 9.48044 6.42223 9.36617C5.84775 9.2519 5.32006 8.96984 4.90588 8.55566C4.4917 8.14148 4.20964 7.61379 4.09537 7.0393C3.9811 6.46482 4.03975 5.86936 4.2639 5.32821C4.48805 4.78706 4.86764 4.32453 5.35466 3.99911C5.84168 3.67369 6.41426 3.5 7 3.5C7.78518 3.50089 8.53794 3.81319 9.09314 4.3684C9.64834 4.9236 9.96065 5.67636 9.96154 6.46154ZM14 7C14 8.38447 13.5895 9.73785 12.8203 10.889C12.0511 12.0401 10.9579 12.9373 9.67879 13.4672C8.3997 13.997 6.99224 14.1356 5.63437 13.8655C4.2765 13.5954 3.02922 12.9287 2.05026 11.9497C1.07129 10.9708 0.404603 9.7235 0.134506 8.36563C-0.13559 7.00776 0.00303299 5.6003 0.532846 4.32122C1.06266 3.04213 1.95987 1.94888 3.11101 1.17971C4.26215 0.410543 5.61553 0 7 0C8.85592 0.00195988 10.6353 0.740087 11.9476 2.05242C13.2599 3.36475 13.998 5.14409 14 7ZM12.9231 7C12.9222 6.20276 12.7606 5.41389 12.4478 4.68055C12.1351 3.94721 11.6777 3.28448 11.1029 2.73197C10.5282 2.17947 9.84794 1.74856 9.10284 1.46499C8.35773 1.18143 7.56309 1.05104 6.76644 1.08163C3.59625 1.20413 1.06818 3.84461 1.07693 7.01683C1.07997 8.46093 1.61258 9.85376 2.57385 10.9314C2.96533 10.3636 3.46261 9.87664 4.03846 9.49711C4.08756 9.46469 4.14593 9.44924 4.20464 9.45313C4.26335 9.45702 4.31917 9.48003 4.36356 9.51865C5.09531 10.1516 6.03048 10.4999 6.99798 10.4999C7.96549 10.4999 8.90065 10.1516 9.63241 9.51865C9.6768 9.48003 9.73261 9.45702 9.79133 9.45313C9.85004 9.44924 9.9084 9.46469 9.9575 9.49711C10.5341 9.87644 11.0321 10.3634 11.4241 10.9314C12.3902 9.84983 12.9238 8.4502 12.9231 7Z" />
                        </svg>
                        <span >&nbsp;&nbsp; {userInfo?.name}</span>
                    </h3>
                </div>

                <div className='col-span-12 bg-[#ededed]'>
                    <ul className='p-0 mt-0 mb-[20px]'>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]'>
                            <Link to={'/myprofile'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> My Profile </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/rolling-commission'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Rolling Commission </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/account-statement'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Account Statement </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/bet-history'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Bets History </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/profit-loss'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Profit &amp; Loss </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li >
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link Link to={'/password-history'} className='flex justify-between items-center w-full' >
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Password History </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[40px] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link Link to={'/activity-log'} className='flex justify-between items-center w-full' >
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Activity Log </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] p-1 justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='w-[12px] h-[12px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li >
                    </ul >

                    <button onClick={logout} type='button' className='w-full bg-[linear-gradient(-180deg,#e93522_0,#be2414_100%)] py-1 border-[1px] border-[solid] border-[#8a0011] border-[1px_0] !text-[#ffffff] text-[1rem] font-bold mb-[5.33333vw] justify-center items-center flex text-center'>
                        <span className='relative left-[2px] text-[#ffffff]' >LOGOUT </span>
                        <FaSignOutAlt className='ml-[.5rem]' />

                    </button>
                </div >
            </div >



            {/* Mobile */}
            <div className='grid lg:hidden grid-col-12 relative top-[56px]'>
                <div className='col-span-12'>
                    <h3 className='!pl-[7px] bg-[linear-gradient(-180deg,_#2E4B5E_0%,_#243A48_82%)] text-[#ffffff] text-[1rem] leading-[2.2] text-center m-0 flex items-center flex-row justify-start font-medium'>
                        <svg className='w-[.9rem] h-[.9rem]' width="14" height="14" viewBox="0 0 14 14" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.96154 6.46154C9.96154 7.04727 9.78785 7.61986 9.46243 8.10688C9.13701 8.5939 8.67448 8.97349 8.13333 9.19764C7.59218 9.42179 6.99672 9.48044 6.42223 9.36617C5.84775 9.2519 5.32006 8.96984 4.90588 8.55566C4.4917 8.14148 4.20964 7.61379 4.09537 7.0393C3.9811 6.46482 4.03975 5.86936 4.2639 5.32821C4.48805 4.78706 4.86764 4.32453 5.35466 3.99911C5.84168 3.67369 6.41426 3.5 7 3.5C7.78518 3.50089 8.53794 3.81319 9.09314 4.3684C9.64834 4.9236 9.96065 5.67636 9.96154 6.46154ZM14 7C14 8.38447 13.5895 9.73785 12.8203 10.889C12.0511 12.0401 10.9579 12.9373 9.67879 13.4672C8.3997 13.997 6.99224 14.1356 5.63437 13.8655C4.2765 13.5954 3.02922 12.9287 2.05026 11.9497C1.07129 10.9708 0.404603 9.7235 0.134506 8.36563C-0.13559 7.00776 0.00303299 5.6003 0.532846 4.32122C1.06266 3.04213 1.95987 1.94888 3.11101 1.17971C4.26215 0.410543 5.61553 0 7 0C8.85592 0.00195988 10.6353 0.740087 11.9476 2.05242C13.2599 3.36475 13.998 5.14409 14 7ZM12.9231 7C12.9222 6.20276 12.7606 5.41389 12.4478 4.68055C12.1351 3.94721 11.6777 3.28448 11.1029 2.73197C10.5282 2.17947 9.84794 1.74856 9.10284 1.46499C8.35773 1.18143 7.56309 1.05104 6.76644 1.08163C3.59625 1.20413 1.06818 3.84461 1.07693 7.01683C1.07997 8.46093 1.61258 9.85376 2.57385 10.9314C2.96533 10.3636 3.46261 9.87664 4.03846 9.49711C4.08756 9.46469 4.14593 9.44924 4.20464 9.45313C4.26335 9.45702 4.31917 9.48003 4.36356 9.51865C5.09531 10.1516 6.03048 10.4999 6.99798 10.4999C7.96549 10.4999 8.90065 10.1516 9.63241 9.51865C9.6768 9.48003 9.73261 9.45702 9.79133 9.45313C9.85004 9.44924 9.9084 9.46469 9.9575 9.49711C10.5341 9.87644 11.0321 10.3634 11.4241 10.9314C12.3902 9.84983 12.9238 8.4502 12.9231 7Z" />
                        </svg>
                        <span className='text-[3.73vw]'>&nbsp;&nbsp;{userInfo?.name}</span>
                    </h3>
                </div>

                <div className='col-span-12 bg-[#ededed]'>
                    <ul className='p-0 mt-0 mb-[20px]'>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]'>
                            <Link to={'/myprofile'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> My Profile </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/rolling-commission'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Rolling Commission </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/account-statement'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Account Statement </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/bet-history'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Bets History </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link to={'/profit-loss'} className='flex justify-between items-center w-full'>
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Profit &amp; Loss </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li >
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link Link to={'/password-history'} className='flex justify-between items-center w-full' >
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Password History </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li>
                        <li className='pt-[8px] pr-[8px] pb-[8px] pl-[8px] h-[unset] text-[#2789ce] outline-[0] relative font-bold leading-[1.6] bg-[#ffffff] text-[15.6px] flex justify-between items-center border-b-[1px]  border-b-[#e0e6e6]' tabIndex="0">
                            <Link Link to={'/activity-log'} className='flex justify-between items-center w-full' >
                                <div className='text-[4vw] text-[#2789ce] font-bold leading-[1.6]'> Activity Log </div>
                                <div className='flex border-[1px] border-[solid] border-[#1e1e1e] bg-[#ffffff] text-[#1e1e1e] w-[6.4vw] h-[6.4vw] justify-center items-center rounded-[4px]'>
                                    <span>
                                        <svg className='relative left-[1px]' width="8" height="13" viewBox="0 0 8 13" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 6.32L1.68 0L0 1.67L4.65 6.32L0 10.97L1.68 12.64L8 6.32Z" />
                                        </svg>
                                    </span>
                                </div>
                            </Link>
                        </li >
                    </ul >

                    <button onClick={logout} type='button' className='w-full bg-[linear-gradient(-180deg,#e93522_0,#be2414_100%)] h-[12.8vw] border-[1px] border-[solid] border-[#8a0011] border-[1px_0] !text-[#ffffff] text-[1.14rem] font-bold mb-[5.33333vw] justify-center items-center flex text-center'>
                        <span className='relative left-[2px] text-[#ffffff] text-[4.26vw]' >LOGOUT </span>
                        <FaSignOutAlt className='ml-[.5rem]' />

                    </button>
                </div >




            </div >
        </>
    );
}

export default Account;
