import '../../../App.css'

const InstantWorli = () => {
    return (
        <>
            <div className='bg-[#fff]'>
                <div className="">
                    <div id="rulesList___BV_modal_body_" className="modal-body">
                        <div id="rulesBoxContainer">
                            <div>
                                    No Rules Found.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default InstantWorli