import '../../../App.css'

const DragonTiger = () => {
    return (
        <>
            <div className='bg-[#fff] h-[500px] overflow-auto'>
                <div className="">
                    <div id="rulesList___BV_modal_body_" className="modal-body">
                        <div id="rulesBoxContainer">
                            <div>
                                <p>
                                    <img src='../rulesImg/dt.webp' />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default DragonTiger