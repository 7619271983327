import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Login from './pages/login';
import Inplay from './pages/inplay';
import AllSportHighlights from './pages/allsport-highlight';
import FullMarket from './pages/fullMarket';
import MyProfile from './pages/myprofile';
import RollingCommission from './pages/rollingCommission';
import AccountStatement from './pages/accountStatement';
import BetHistory from './pages/betHistory';
import ProfitLoss from './pages/profit-loss';
import ProfitLossEvents from './pages/profit-loss-events';
import ProfitLossEventMarkets from './pages/profit-loss-event-markets';
import ProfitLossBetHistory from './pages/profit-loss-bet-history';

import PasswordHistory from './pages/password-history';
import ActivityLog from './pages/activityLog';
import MultiMarket from './pages/multimarket';
import Account from './pages/account';
import LiveCasino from './pages/live-casino';
import TipsPreview from './pages/tips-previews';
import Sports from './pages/sports/index';
import VirtualSports from './pages/virtual-sports';
import Setting from './pages/setting';
import Casino from './pages/Casino';
import { WebSocketContext, socket } from './context/websocket';

function App() {

  return (
    <WebSocketContext.Provider value={socket}>
      {/* <CasinoWebSocketContext.Provider value={casinoSocket}> */}
      <Routes>
        <Route index element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/inplay' element={<Inplay />} />
        <Route path='/allsport-highlight/:id' element={<AllSportHighlights />} />
        <Route path='/fullMarket/:event_id/:is_inplay' element={<FullMarket />} />
        <Route path='/fullMarket/:event_id/:is_inplay/:open_date' element={<FullMarket />} />

        <Route path='/casino/:id' element={<Casino />} />

        <Route path='/myprofile' element={<MyProfile />} />
        <Route path='/rolling-commission' element={<RollingCommission />} />
        <Route path='/account-statement' element={<AccountStatement />} />
        <Route path='/bet-history' element={<BetHistory />} />
        <Route path='/profit-loss' element={<ProfitLoss />} />
        <Route path='/profit-loss-event/:event_type_id/:from_date/:to_date' element={<ProfitLossEvents />} />
        <Route path='/profit-loss-event-market/:match_id/:from_date/:to_date' element={<ProfitLossEventMarkets />} />
        <Route path='/profit-loss-bet-history/:match_id/:market_id/:from_date/:to_date' element={<ProfitLossBetHistory />} />

        <Route path='/password-history' element={<PasswordHistory />} />
        <Route path='/activity-log' element={<ActivityLog />} />
        <Route path='/multimarket' element={<MultiMarket />} />
        <Route path='/account' element={<Account />} />
        <Route path='/live-casino' element={<LiveCasino />} />
        <Route path='/tips-previews' element={<TipsPreview />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/virtual-sports' element={<VirtualSports />} />
        <Route path='/setting' element={<Setting />} />

      </Routes>
      {/* </CasinoWebSocketContext.Provider> */}
    </WebSocketContext.Provider>
  );
}

export default App;
