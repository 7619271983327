import '../../../App.css'

const Evolution = () => {

    return (
        <>
            <div className='bg-[#fff]'>
                <div className="">
                    <div id="rulesList___BV_modal_body_" className="modal-body">
                       No Rules Found.
                    </div>
                </div>
            </div>
        </>
    )

}
export default Evolution