import '../../../App.css'

const Baccarat29Card = () => {

    return (
        <>
            <div className='bg-[#fff] h-[500px] overflow-auto'>
                <div className="">
                    <div id="rulesList___BV_modal_body_" className="modal-body">
                        <div id="rulesBoxContainer">
                            <div>
                                <h1 className='text-[20px] bold'>29 Card Baccarat</h1>

                                <ul>
                                    <li>in 29 Card Baccarat total 29 Cards will be used.</li>
                                    <li>Cards 2 to 8 all Colour cards &amp; Only 9 of Spade will count</li>
                                    <li>this game will be playing between two players A and B each player will get 3 Cards </li>
                                    <li>in this game point value will be counted as per card value eg.( 2=2 point, 4=4point )</li>


                                </ul> <br /><br />

                                <h6>Winner</h6>
                                <ol>
                                    <li>if any player has trio he will win if both have trio the one who has got higest trio will win.</li>
                                    <li>if nobody has trio baccarat value will be compared &amp; Higher baccarat value game will win.</li>
                                    <li>to get the baccarat value,from the total of 3 Cards last digit will be taken as baccarat value.</li>
                                    <li>eg. Cards (4,6,8) 4+6+8=18 here last digit is 8 then baccarat value will be 8. </li>
                                    <li>eg. Cards (3,2,2) 3+2+2=7 here last digit is 8 then baccarat value will be 7.</li>
                                    <li><strong>if both players have same baccarat value then higest card of both the game will be compared whose card is Higher will Win.</strong></li>
                                    <li>if 1st higest card will equal then 2nd higest card will be compared.</li>
                                    <li>if 2nd higest card will equal then 3rd higest card will be compared.</li>
                                    <li>if 3rd higest card will equal then game will be counted as tied and bet amount will be refunded.</li>

                                </ol> <br />
                                <h6>High Card</h6>
                                <ol>
                                    <li>in this Game higher Card value of both Player-A &amp; Player-B will be counted</li>
                                    <li>if 1st higest card will equal then 2nd higest card will be compared.</li>
                                    <li>if 2nd higest card will equal then 3rd higest card will be compared.</li>
                                    <li>if 3rd higest card will equal then game will be counted as tied and bet amount will be refunded.</li>

                                </ol><br />

                                <h6>Pair</h6>
                                <ol>
                                    <li>in this game you have to place bet for pair in any of selected game.</li>
                                    <li> Cards 4,4,6 Counted as pair and 3,3,3 Counted as trio.</li>

                                </ol><br />

                                <h6>Colour Plus</h6>
                                <ol>
                                    <li>in this game you have to place bet for Colour Plus in any of selected game.</li>
                                    <li>if you get sequance of diffrent suit,You will get 2 times of betting amount.</li>
                                    <li>if you get colour of same suit, you will get 5 times of betting amount.</li>
                                    <li>if you get trio, you will get 20 times of betting amount.</li>
                                    <li>if you get pure sequance ,you will get 30 times of betting amount</li>
                                    <li>if you get pure sequance you will not get price of colour. you will get only one price in this case.</li>
                                </ol><br />

                                <h6>Lucky-9</h6>
                                <ul>
                                    <li> this bet for having card 9 amonf any of total six cards of both game.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Baccarat29Card