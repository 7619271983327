import { useParams } from 'react-router-dom';
import TEENPATTI2020 from '../20-20_TEENPATTI'
import Lucky7 from '../lucky7';
import DRAGONTIGER2020 from '../20-20_DRAGON_TIGER'
import ANDARBAHARA from '../ANDAR_BAHAR_A'
import CARDS32A from '../32_CARDS_A'
import TEENPATTI1DAY from '../1-DAY_TEENPATTI'
import BACCARAT from '../BACCARAT'
import POKERA2020 from '../POKERA2020'
import DRAGONTIGER from '../DRAGONTIGER'
import DRAGONTIGER1DAY from '../DRAGONTIGER1DAY'
import Appconfig from '@/../config/config'
import BACCARATCARD29 from 'pages/29-CARD_BACCARAT';
import MULFISTEENPATTI from 'pages/MULFIS_TEENPATTI';
import DTLTEENPATTI from 'pages/DTL-TEEN-PATTI';
import BACCARAT_A from 'pages/BACCARAT-A';
import BACCARAT_C from 'pages/BACCARAT-C';
import FASTDRAGONTIGER from 'pages/FAST-DRAGON-TIGER';
import FASTLUCKY_7 from 'pages/FAST-LUCKY-7';
import LUCKY7B from 'pages/lucky7B';
import CARD_3_JUDGEMENT from 'pages/3-CARD-JUDGEMENT';
import CASINO_WAR from 'pages/CASINO-WAR';
import INSTANT_WORLI from 'pages/INSTANT-WORLI';
import BOLLYWOOD_CASINO from 'pages/BOLLYWOOD-CASINO';
import INA_MINA_DIKA from 'pages/INA-MINA-DIKA';
import AMAR_AKABAR_ANTHONY from 'pages/AMAR-AKABAR-ANTHONY';
import DTL_A from 'pages/DTL-A';
import LOTTERY from 'pages/LOTTERY';
import CARD_RACE_20_20 from 'pages/20-20_CARD_RACE';
import ONE_CARD_METER from 'pages/ONE_CARD_METER';
import ONE_CARD_20_20 from 'pages/1-CARD_20-20';
import TRIO from 'pages/TRIO';
import KBC from 'pages/KBC';
import CASINO_METER from 'pages/CASINO_METER';
import POINT_TP from 'pages/POINT-TEENPATTI';
import JOKER_TP from 'pages/JOKER-TEENPATTI';


const Casino = () => {
    const { id } = useParams();

    switch (id) {
        case Appconfig.casino_type_id['t20']:
            return <TEENPATTI2020 />;
        case Appconfig.casino_type_id['Lucky7A']:
            return <Lucky7 />;
        case Appconfig.casino_type_id['dt20']:
            return <DRAGONTIGER2020 />;
        case Appconfig.casino_type_id['ab']:
            return <ANDARBAHARA />;
        case Appconfig.casino_type_id['32c']:
            return <CARDS32A />;
        case Appconfig.casino_type_id['1daytp']:
            return <TEENPATTI1DAY />;
        case Appconfig.casino_type_id['baccarat']:
            return <BACCARAT />;
        case Appconfig.casino_type_id['poker20A']:
            return <POKERA2020 />;
        case Appconfig.casino_type_id['dt']:
            return <DRAGONTIGER />;
        case Appconfig.casino_type_id['1daydt']:
            return <DRAGONTIGER1DAY />;
        case Appconfig.casino_type_id['dtlA']:
            return <DTL_A />;
        case Appconfig.casino_type_id['baccaratcard29']:
            return <BACCARATCARD29 />;
        case Appconfig.casino_type_id['mulfisteenpatti']:
            return <MULFISTEENPATTI />;
        case Appconfig.casino_type_id['dtlteenpatti']:
            return <DTLTEENPATTI />;
        case Appconfig.casino_type_id['baccarat-A']:
            return <BACCARAT_A />;
        case Appconfig.casino_type_id['baccarat-C']:
            return <BACCARAT_C />;
        case Appconfig.casino_type_id['fdt']:
            return <FASTDRAGONTIGER />;
        case Appconfig.casino_type_id['fl7']:
            return <FASTLUCKY_7 />;
        case Appconfig.casino_type_id['Lucky7B']:
            return <LUCKY7B />;
        case Appconfig.casino_type_id['3cardJ']:
            return <CARD_3_JUDGEMENT />;
        case Appconfig.casino_type_id['casinowar']:
            return <CASINO_WAR />;
        case Appconfig.casino_type_id['instantworli']:
            return <INSTANT_WORLI />;
        case Appconfig.casino_type_id['bollywoodcasino']:
            return <BOLLYWOOD_CASINO />;
        case Appconfig.casino_type_id['imd']:
            return <INA_MINA_DIKA />;
        case Appconfig.casino_type_id['aaa']:
            return <AMAR_AKABAR_ANTHONY />;
        case Appconfig.casino_type_id['lottery']:
            return <LOTTERY />;
        case Appconfig.casino_type_id['20-20-race']:
            return <CARD_RACE_20_20 />;
        case Appconfig.casino_type_id['one-card-meter']:
            return <ONE_CARD_METER />;
        case Appconfig.casino_type_id['1-card-20-20']:
            return <ONE_CARD_20_20 />;
        case Appconfig.casino_type_id['trio']:
            return <TRIO />;
        case Appconfig.casino_type_id['kbc']:
            return <KBC />;
        case Appconfig.casino_type_id['casino-meter']:
            return <CASINO_METER />;
        case Appconfig.casino_type_id['point-tp']:
            return <POINT_TP />;
        case Appconfig.casino_type_id['joker-tp']:
            return <JOKER_TP />;
        default:
            return <div>Casino not found</div>;
    }
};

export default Casino;