import '../../../App.css'

const Teenpatti2020 = () => {

    return (
        <>
            <div className='bg-[#fff] h-[500px] overflow-auto'>
                <div className="">
                    <div id="rulesList___BV_modal_body_" className="modal-body">
                        <div id="rulesBoxContainer">
                            <div>
                                <title></title>
                                <div>
                                    <div>
                                        <div>
                                            <h3><strong>TEEN PATTI&nbsp;</strong></h3>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <p>1. Teen Patti is 3 card poker version of Asia.</p>
                                            <p>2. Teen Patti lets the player bet against the dealer, or bet on the value of their own three-card hand, or bet both against the dealer and on the value of their own hand. <strong>You only need three consecutive cards to make a STRAIGHT and three of a suit to make a FLUSH</strong>. In Three Card Poker, a STRAIGHT Beats a FLUSH.</p>
                                            <p>3. Teen Patti (also spelled Teen Patti and sometimes referred to as Flash) is a gambling card game that originated in India and became popular in Southeast Asia. Teen Patti is India’s twist on 3 card poker and is identical to 3-card brag which is popular in the UK.</p>
                                            <p>4. &nbsp;Ranking of the hands</p>
                                            <p>5. Aces are ranked the highest and 2's are the lowest. The objective of the game is to have the best 3-card hand and to maximize the pot before the showdown. The categories are ranked as follows (from high to low):</p>
                                            <p>&nbsp;</p>
                                            <p>--&gt;&nbsp; Trail (three of a kind): Three cards of the same rank. Three aces are the highest and three 2’s are the lowest.&nbsp;</p>
                                            <p>
                                                <img src='../rulesImg/teenpatti2020-1.webp' />
                                            </p>

                                            <p>--&gt;&nbsp; Pure Sequence (Straight Flush): Three consecutive cards of the same suit.&nbsp;</p>
                                            <p>
                                                <img src='../rulesImg/teenpatti2020-2.webp' />
                                            </p>

                                            <p>--&gt;&nbsp; Sequence (Straight): Three consecutive cards not all in the same suit. &nbsp;</p>
                                            <p>
                                                <img src='../rulesImg/teenpatti2020-3.webp' />
                                            </p>

                                            <p>--&gt;&nbsp;  Color (Flush): Three cards of the same suit that are not in sequence. When comparing two colors, first compare the highest card. If these are equal, compare the second and if these are equal compare the lowest. Highest flush is A-K-J and the lowest flush is 5-3-2. &nbsp;</p>
                                            <p>
                                                <img src='../rulesImg/teenpatti2020-4.webp' />
                                            </p>

                                            <p>--&gt;&nbsp;  Pair (two of a kind): Two cards of the same rank. Between two pairs, the one with the higher value is the winner. If the pairs are of equal value then the kicker card determines the winner. The highest pair is A-A-K and the lowest is 2-2-3. &nbsp;</p>
                                            <p>
                                                <img src='../rulesImg/teenpatti2020-5.webp' />
                                            </p>

                                            <p>--&gt;&nbsp;   High Card: A hand in which the three cards are not in sequence, not all the same suit and no two cards have the same rank. If two players share a common high card, the next highest card is used to determine the winner. The best high card hand would be an AKJ of different suits and the worst is 5-3-2. &nbsp;</p>
                                            <p>
                                                <img src='../rulesImg/teenpatti2020-6.webp' />
                                            </p>

                                            <p><br /></p>
                                            <p><strong>--&gt; <u>FANCY BETS</u></strong></p>
                                            <p><strong>- PAIR (DOUBLE) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&lt;&gt; PAY OUT = 1:4</strong><br /><strong>- FLUSH (COLOR) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>&lt;&gt; PAY OUT =&nbsp;</strong>1:8</strong><br /><strong>- STRAIGHT (ROWN) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>&lt;&gt; PAY OUT =</strong> 1:14</strong><br /><strong>- STRAIGHT FLUSH (PAKKI ROWN) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>&lt;&gt; PAY OUT =</strong> 1:40</strong><br /><strong>- TRIO (TEEN) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&lt;&gt; PAY OUT =</strong> 1:75</strong><br /><strong>- PUTLA (1 PICTURE IN GAME) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&lt;&gt; PAY OUT =</strong> 1:0.70</strong><br /><strong>- PUTLA ( 2 PICTURE IN GAME) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong><strong><strong>&lt;&gt; PAY OUT =</strong></strong><strong>&nbsp;1:4</strong><br /><strong>- PUTLA ( 3 PICTURE IN GAME) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>&lt;&gt; PAY OUT =</strong> 1:25</strong><br /><strong>- LOVE MARRIAGE (Q &amp; K WITH SAME SUIT)</strong><strong><strong>&lt;&gt; PAY OUT =</strong></strong><strong>&nbsp;1:25</strong><br /><strong>- BF LOVE GF ( J &amp; Q WITH SAME SUIT ) &nbsp; &nbsp; &nbsp; &nbsp;&lt;&gt; PAY OUT = 1:25</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <p><br /></p>
                                <p><strong><u><span className='text-[20px]' >NOTE</span></u></strong> -: <span className='text-[17px]'>For Game Cancelation, Void all authority have management if they found suspicious aur technically issue...</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Teenpatti2020